@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/animations" as *;

.add-modal {
  &__label {
    @include form-label;
  }

  &__input {
    @include form-field;
    padding-left: $spacing-unit-sm;
  }
}
