@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.dropdown {
  margin-top: 0.5rem;
  overflow: hidden;

  &__title {
    @include form-label;
    cursor: pointer;
  }

  &__title-line {
    @include flex-center(
      $justify-content: space-between,
      $align-content: flex-start
    );
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;

    &--open {
      max-height: 30rem;
      transition: max-height 0.5s;
    }
  }

  &__item {
    margin-top: $spacing-unit-xxs;
    display: flex;
    align-items: flex-start;
    @include paragraph;
  }

  &__arrow {
    fill: $primary;
    height: $icon-size;
    width: $icon-size;
  }
}
