@use "../../styles/typefaces" as *;
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.cardPrev {
  width: 3.75rem;
  height: 5.625rem;
  position: relative;
  opacity: 0.9;

  @include tablet {
    width: 11.25rem;
    height: 16.875rem;
  }

  &__poster-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: center center;
    border-radius: $border-radius;
  }

  &:hover {
    border: 1px $primary solid;
    opacity: 1;
  }

  &--selected {
    border: 3px $accent solid;
    opacity: 1;
    &:hover {
      border: 3px $accent solid;
    }
  }
}
