@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.jar {
  display: flex;
  flex-direction: column;

  &__top-space {
    width: 100%;
    display: flex;
    align-items: flex-end;
    background-color: $primary-background;
    z-index: 1;
    @include bottom-margin;
  }

  &__title {
    z-index: 1;
  }

  &__add-btn {
    height: 60px;
    width: 60px;
    background-color: $primary;
    font-size: 3rem;
    border-radius: 50%;
    position: fixed;
    right: 25px;
    bottom: 25px;
    @include flex-center;
    transition: $transition;

    &:hover {
      background-color: $secondary;
    }
  }

  &__plus-icon {
    fill: $light-text;
    height: $big-icon;
    width: $big-icon;
  }
}
