@use "../../styles/typefaces" as *;
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.picker-page {
  width: 100%;

  &__page {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__form-container {
    &--hidden {
      display: none;
    }
  }

  &__no-movies {
    @include flex-center;
    height: 100%;
  }
}
