@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.add {
  width: 100%;
  &__search-results {
    @include flex-center($justify-content: flex-start);
    gap: $spacing-unit-sm;
    flex-wrap: wrap;
    @include vertical-padding;
  }

  &--selected {
    position: relative;
    left: $spacing-unit-med;
  }

  &__search-results {
    width: 100%;
  }

  &__center-container {
    width: 100%;
    height: 100%;

    &--centered {
      @include tablet {
        @include flex-center;
        flex-direction: column;
      }
    }
  }

  &__no-results {
    margin: auto;
    display: flex;
    gap: $spacing-unit-sm;
    flex-direction: column;
  }
}
