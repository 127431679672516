@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.navbar {
  @include dark;
  @include side-padding;
  @include flex-center;
  height: $nav-height-mob;
  position: sticky;
  width: 100vw;
  z-index: 2;
  box-shadow: $box-shadow;

  @include tablet {
    height: $nav-height-tab;
    justify-content: space-between;
    gap: $spacing-unit-xl;
  }

  &__left {
    @include flex-gap;
    @include flex-center;
    flex-direction: column;
    width: 100%;
    max-width: 30rem;
    flex-shrink: 0;

    @include tablet {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__links {
    width: 100%;
    @include flex-center($justify-content: space-around);
    @include flex-gap;
  }

  &__avatar {
    height: $big-icon;
    width: $big-icon;
    border-radius: 50%;
    background-color: $primary;
    @include tablet-bigger-only;
    flex-shrink: 0;
  }

  &__menu {
    @include flex-center;

    &--mobile {
      @include mobile-only;
    }

    &--tablet {
      @include tablet-bigger-only;
    }

    @include tablet {
      width: 100%;
      max-width: 25rem;
    }
  }

  &__link {
    line-height: 2rem;
    &.active {
      color: $accent;
      font-weight: 700;
    }
  }
  &__logo {
    @include flex-center($align-content: flex-end);
    gap: 0.5rem;
  }

  &__logo-img {
    width: 2.5rem;
  }

  &__logo-text {
    @include small-heading;
    line-height: 2rem;
  }
}
