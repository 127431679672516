@use "../../styles/_mixins.scss" as *;
@use "../../styles/_variables.scss" as *;

.rating {
  color: $primary;
  @include small-heading;
  font-weight: 700;

  @include tablet {
    @include sm-paragraph;
  }

  &__icon {
    @include small-icon;
    position: relative;
    top: $icon-size/2;
    left: $icon-spacing-unit;

    @include tablet {
      top: 2px;
      left: $spacing-unit-xs;
    }
  }
}
