@use "../../styles/_mixins.scss" as *;
@use "../../styles/_variables.scss" as *;

.del-modal {

  &__text {
    @include top-margin;
    @include bottom-margin;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
