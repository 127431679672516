@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/animations" as *;

.close-x {
  fill: $dark-text;
  position: absolute;
  right: $icon-spacing-unit;
  top: $icon-spacing-unit;
  @include small-icon;

  &--light {
    fill: $light-text;
  }
}
