@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/animations" as *;

.side-form {
  @include left-padding;
  @include sm-right-padding;
  @include side-padding;
  @include vertical-padding;
  height: calc(100vh - $nav-height-mob);
  width: 100vw;
  overflow-y: auto;
  background-color: $secondary-background;
  box-shadow: $box-shadow;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
  animation: slideIn $transition forwards;

  @include tablet {
    width: calc($sidebar-width + $spacing-unit-lg);
    height: calc(100vh - $nav-height-tab);
  }
}
