@use "../../styles/typefaces" as *;
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.not-found {
  @include flex-center;
  min-width: 100%;
  height: 100%;
  flex-direction: column;
}
