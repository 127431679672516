@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.top {
  @include flex-center(
    $justify-content: space-around,
    $align-content: flex-start
  );
  @include bottom-margin;
  @include sm-vertical-padding;
  @include sm-side-padding;
  flex-direction: column;
  width: 100%;
  background-color: $secondary-background;
  border-radius: $border-radius;

  @include tablet {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: $spacing-unit-xl;
  }

  &__img {
    width: 100%;
    max-width: 22rem;

    height: auto;
    border-radius: $border-radius;
    @include bottom-margin;

    @include tablet {
      min-width: 14rem;
    }

    @include desktop {
      width: 15rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: $spacing-unit-sm;
    max-width: 30rem;
  }

  &__small {
    @include sm-paragraph;
  }
}
