@use "../../styles/_mixins.scss" as *;
@use "../../styles/_variables.scss" as *;

.movie-modal {
  &__close,
  &__edit-del {
    position: absolute;
    top: $icon-spacing-unit;
  }

  &__close {
    right: $icon-spacing-unit;
    @include small-icon;
    fill: $dark-text;
  }

  &__edit-del {
    left: $icon-spacing-unit;
  }
}
