@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

$thumb-size: $field-input-height;
$track-height: calc($field-input-height - $spacing-unit-xs);

// replace default thumb circle
@mixin new-thumb {
  -webkit-appearance: none;
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 50%;
  border: 1px solid $secondary;
  background: $primary;
  cursor: pointer;
}

.slider {
  display: flex;
  @include flex-center(
    $justify-content: space-between,
    $align-content: flex-end
  );

  &__contents {
    position: relative;
    width: 85%;
  }

  &__slider {
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    width: 100%;
    height: calc($thumb-size - $spacing-unit-xs);
    border-radius: $border-radius;
    background: transparent;
    outline: none;
    transition: $transition;
    cursor: pointer;
    z-index: 2;

    &::-webkit-slider-thumb {
      @include new-thumb;
    }
    &::-moz-range-thumb {
      @include new-thumb;
    }
  }

  &__track {
    position: absolute;
    width: 100%;
    height: $track-height;
    background: $light-background;
    border-radius: $border-radius;
  }

  &__track-filled {
    position: absolute;
    height: 100%;
    background: $primary;
    border-radius: $border-radius;
    z-index: 1;
  }

  &__label {
    @include form-label;
  }
}
