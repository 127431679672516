// @use "../../styles/typefaces" as *;
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;
@use "../../styles/animations" as *;

.sidebar {
  @include dark;
  position: sticky;
  @include top-padding;
  width: 100vw;
  height: calc(100vh - $nav-height-mob);
  overflow-y: auto;
  box-shadow: $box-shadow;
  z-index: 3;
  transition: $transition;
  animation: slideIn $transition forwards;

  @include tablet {
    width: 25vw;
    height: calc(100vh - $nav-height-tab);
  }

  &--closed {
    animation: slideOut $transition forwards;
    flex: 0 0 0px;
  }

  &__close {
    @include small-icon;
    fill: $light-text;
    position: absolute;
    top: $icon-spacing-unit;
    right: $icon-spacing-unit;

    &:hover {
      cursor: pointer;
      fill: $primary;
    }
  }

  &__right-arrow {
    fill: $primary;
    @include small-icon;
    position: absolute;
    right: $icon-spacing-unit;
  }

  &__jar-container {
    border-bottom: $divider;
    padding-right: $icon-spacing-unit;
    @include flex-center($justify-content: space-between);
  }

  &__jar-link {
    @include left-padding;
    padding-right: $icon-spacing-unit;
    @include flex-center($justify-content: flex-start);
    height: 2.75rem;
    width: 100%;

    &.active {
      color: $accent;
      border-left: $accent-border;

      .sidebar__link-text {
        // offsets accent
        position: relative;
        left: -5px;
      }
    }

    &:hover {
      cursor: pointer;
      color: $accent;
    }
  }

  &__jar-del {
    @include small-icon;
    fill: $light-text;

    &:hover {
      fill: $error;
    }
  }

  &__title {
    color: $primary;
    @include form-label;
    @include side-padding;
    border-bottom: $divider;
    margin: 0; //come back why isn't this auto?
    height: $field-input-height;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  &__link {
    @include flex-center;
    width: 100%;
    @include sm-vertical-padding;

    &.active {
      .sidebar__title {
        border-left: $accent-border;
        color: $accent;
      }

      .sidebar__right-arrow {
        fill: $accent;
      }
    }
  }


  &__add-new-text {
    &--hidden {
      display: none;
    }
  }

  &__jars {
    border-bottom: $divider;
  }
}
