@use "../../styles/_mixins.scss" as *;
@use "../../styles/_variables.scss" as *;

.icons {
  display: flex;
  gap: $spacing-unit-xs;

  &__edit,
  &__del {
    @include small-icon;
    fill: $dark-text;
  }

  &__del {
    &:hover {
      fill: $error;
    }
  }

  &__edit {
    &:hover {
      cursor: not-allowed;
    }
  }
}
