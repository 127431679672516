@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.search {
  width: 100%;
  max-width: $max-form-field-width;
  position: relative;
  margin: auto;
  @include flex-center;

  &--tablet {
    @include tablet-bigger-only;
  }

  &--mobile {
    @include mobile-only;
  }

  &__input {
    @include form-field;
  }

  &__icon {
    position: absolute;
    fill: $dark-text;
    left: $icon-spacing-unit;
  }
}
