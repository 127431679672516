@use "./styles/typefaces" as *;
@use "./styles/variables" as *;
@use "./styles/mixins" as *;

/* Globals */

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
input,
textarea,
button {
  font-family: "AvenirNext LT Pro", Arial, sans-serif;
  color: $light-text;
  font-weight: 400;
}

body {
  min-height: 100vh;
  background-color: $primary-background;
}

a,
:visited {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: $accent;
  }
}

/* Text sizing */
h1 {
  @include page-header;
}

h2 {
  @include subheader;
}

h3 {
  @include small-heading;
}

h4 {
  @include tiny-heading;
}

p {
  @include paragraph;
}

/* Custom checkbox styling */
input[type="checkbox"] {
  margin-right: $icon-spacing-unit;
  width: $icon-size;
  height: $icon-size;
  background-color: $light-background;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  position: relative;

  &:checked {
    background-color: $primary;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.375rem;
      height: 0.625rem;
      border: solid white;
      border-width: 0 1.5px 1.5px 0;
      transform: translate(-50%, -60%) rotate(45deg);
    }
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 0.375rem;

  &-thumb {
    background-color: $primary;
    border-radius: $border-radius;

    &:hover {
      background-color: $secondary;
    }
  }

  &-track {
    background-color: $light-background;
  }
}
