@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.select {
  height: $field-input-height;
  @include paragraph;
  position: relative;
  background-color: $light-background;
  color: $dark-text;
  border-radius: $border-radius;
  text-transform: none;
  font-weight: normal;
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  &__selected {
    height: $field-input-height;
    @include sm-left-padding;
    padding-right: $icon-spacing-unit;
    border-radius: $border-radius;
    @include flex-center($justify-content: space-between);
  }

  &__options {
    @include light-menu;
  }

  &__option {
    @include light-menu-option;
  }
}
