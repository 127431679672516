@use "../../styles/mixins" as *;
@use "../../styles/variables" as *;

.loading {
  width: 100%;
  height: 40vh;
  @include flex-center;

  @include tablet {
    height: 60vh;
  }

  &__circle {
    @include flex-center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    background: linear-gradient(
      to top,
      $secondary-background 65%,
      $primary 100%
    );
  }

  &__circle-inner {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    background-color: $primary-background;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  48% {
    transform: rotate(70deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
