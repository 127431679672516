@use "../../styles/typefaces" as *;
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.card {
  width: 60px;
  height: 90px;
  perspective: 1000px;
  position: relative;

  @include tablet {
    width: 180px;
    height: 270px;

    &:hover {
      .card__back {
        transform: rotateY(0deg);
      }
      .card__front {
        transform: rotateY(180deg);
      }
    }
  }

  &__front,
  &__back {
    @include flex-center;
    text-align: center;
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: ease-in-out 600ms;
    border-radius: $border-radius;
  }

  &__poster-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: center center;
    border-radius: $border-radius;
  }

  &__back {
    background-color: $light-background;
    color: $dark-text;
    @include flex-center($justify-content: space-around);
    flex-direction: column;
    @include sm-side-padding;
    @include sm-bottom-padding;
    @include top-padding;
    transform: rotateY(-180deg);
    position: relative;
  }
  &__overlay {
    background-color: $light-overlay;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &__icon-container {
    position: absolute;
    top: $icon-spacing-unit;
    left: $icon-spacing-unit;
  }
}
