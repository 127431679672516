@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('../assets/fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
        url('../assets/fonts/AvenirNextLTPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNext LT Pro';
    src: url('../assets/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('../assets/fonts/AvenirNextLTPro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}