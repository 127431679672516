@use "../../styles/_mixins.scss" as *;
@use "../../styles/_variables.scss" as *;

.summary {
  &__description {
    max-height: 20rem;
    @include paragraph;
    line-height: 1.6;
    overflow: auto;
    text-align: left;

    @include tablet {
      @include sm-paragraph;
      max-height: 7.5rem;
      overflow: auto;
      line-height: 1.3;
    }
  }

  &__title {
    font-size: 1.33rem;
    font-weight: bold;

    @include tablet {
      font-size: 1rem;
      @include max-lines(2);
    }
  }

  &__title-container {
    margin-bottom: $spacing-unit-sm;

    @include tablet {
      margin: 0;
    }
  }

  &__description,
  &__genres,
  &__vibe,
  &__runtime {
    @include tablet {
      @include xs-paragraph;
    }
  }

  &__text {
    height: 100%;
    @include flex-center($justify-content: space-around);
    flex-direction: column;
    text-align: center;

    @include tablet {
      gap: $spacing-unit-xs;
    }
  }
}
