@use "../../styles/_mixins.scss" as *;
@use "../../styles/_variables.scss" as *;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $dark-overlay;
  z-index: 10;
  @include flex-center;

  &__content {
    display: flex;
    @include side-padding;
    @include vertical-padding;
    padding-top: $spacing-unit-xl;
    flex-direction: column;
    color: $dark-text;
    justify-content: space-between;
    background: $light-background;
    border-radius: $border-radius;
    height: 90vh;
    width: 90vw;
    position: relative;
    overflow: auto;

    @include tablet {
      max-width: 30rem;
      padding-left: $spacing-unit-xl;
      padding-right: $spacing-unit-xl;
      width: auto;
      height: auto;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: $spacing-unit-med;
    @include bottom-margin;
  }

  &__top {
    height: 100%;
  }

  &__footer {
    @include flex-center($justify-content: space-between);
    @include flex-gap;
  }

  &__header {
    @include flex-center($justify-content: flex-end);
  }
}
