@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.cta {
  @include flex-center;
  align-self: flex-end;
  height: $field-input-height;
  width: 100%;
  border-radius: $border-radius;
  color: $dark-text;
  background-color: $accent;
  transition: $transition;
  border: 1px solid $secondary;

  &__contents {
    @include flex-center;
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__icon {
    position: absolute;
    left: $icon-spacing-unit;
  }

  @include tablet {
    max-width: 8rem;
  }

  &:disabled {
    background-color: $secondary-background;
  }

  &:hover:enabled {
    background-color: $primary;
    cursor: pointer;
  }

  &:hover:disabled {
    cursor: not-allowed;
  }

  &--reverse {
    border: 1px solid $secondary-background;
    background-color: $light-background;
    color: $dark-text;

    &:hover:enabled {
      background-color: $secondary-background;
      color: $light-text;
    }
  }

  &--delete {
    background-color: $error;
    color: $light-text;
    border: 1px solid $error;

    &:hover:enabled {
      background-color: $light-background;
      color: $error;
    }
  }
}
