@keyframes slideIn {
  from {
    transform: translateX(-120%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-120%);
    display: none;
  }
}
