@use "../../styles/typefaces" as *;
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.add-form {
  display: flex;
  height: 100vh;
  flex-direction: column;
  gap: $spacing-unit-med;

  &__highlight {
    color: $accent;
  }

  &__label {
    @include form-label;
  }

  &__jar-options {
    max-height: 6rem;
    overflow-y: auto;
  }
}
