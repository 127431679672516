@use "./styles/typefaces" as *;
@use "./styles/variables" as *;
@use "./styles/mixins" as *;

.page {
  display: flex;
  justify-content: space-between;
  max-height: 100vh;

  &__content {
    @include side-padding;
    @include vertical-padding;
    height: calc(100vh - $nav-height-mob);
    overflow-y: auto;
    display: none;

    transition: $transition;

    @include tablet {
      display: flex;
      min-height: calc(100vh - $nav-height-tab);
      width: calc(100vw - $sidebar-width - $spacing-unit-lg);
    }

    &--closed-sidebar {
      transition: $transition;
      display: flex;
      width: 100vw;
    }
  }

  &__sidebar-menu {
    height: $big-icon;
    width: $big-icon;
    @include top-margin;
    @include left-margin;

    &--hidden {
      display: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
