@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.picker {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__form-options {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  &__submit {
    @include tablet {
      display: none;
    }
  }
}
